import { initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { collection, doc, getDoc, getDocs, getFirestore, setDoc, writeBatch } from 'firebase/firestore';
import { getDownloadURL, getMetadata, getStorage, ref, uploadBytes } from 'firebase/storage';
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import { ADMIN_EMAILS, FIREBASE_API, MAIL_SUFFIX } from '../config';

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

const STORAGE = getStorage(firebaseApp);

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  error: null,
};

function getRole(email) {
  if (ADMIN_EMAILS.includes(email)) {
    return 'admin';
  }

  return 'editor';
}

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user, error } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      error,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  login: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  register: () => Promise.resolve(),
  sendPasswordReset: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const [profile, setProfile] = useState(null);

  useEffect(
    () =>
      onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const userRef = doc(DB, 'users', user.uid);

          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            setProfile(docSnap.data());

            dispatch({
              type: 'INITIALISE',
              payload: { isAuthenticated: true, user },
            });
          } else {
            dispatch({
              type: 'INITIALISE',
              payload: { isAuthenticated: false, user: null, error: 'User not allowed to login' },
            });
          }
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    [dispatch],
  );

  const login = (email, password) => {
    try {
      if (!ADMIN_EMAILS.includes(email) && !email.endsWith(MAIL_SUFFIX)) {
        throw new Error('You are not authorized to login');
      } else {
        signInWithEmailAndPassword(AUTH, email, password);
      }
    } catch (error) {
      console.log('burada');
      throw new Error(error);
    }
  };

  const register = (email, password, firstName, lastName) => {
    try {
      if (ADMIN_EMAILS.includes(email) || email.endsWith(MAIL_SUFFIX)) {
        createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
          const userRef = doc(collection(DB, 'users'), res.user?.uid);

          await setDoc(userRef, {
            uid: res.user?.uid,
            email,
            displayName: `${firstName} ${lastName}`,
          });
        });
      } else {
        throw new Error('You are not authorized to register');
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  // create the function to login with google by firebase
  const loginWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const res = await signInWithPopup(AUTH, provider);

      // after login add user to firestore users collection
      if (!ADMIN_EMAILS.includes(res.user?.email) && !res.user?.email.endsWith(MAIL_SUFFIX)) {
        throw new Error('You are not authorized to login');
      } else {
        const userRef = doc(collection(DB, 'users'), res.user?.uid);

        await setDoc(userRef, {
          uid: res.user?.uid,
          email: res.user?.email,
          photoURL: res.user?.photoURL,
          displayName: res.user?.displayName,
          role: getRole(res.user?.email),
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const sendPasswordReset = (email) => {
    try {
      sendPasswordResetEmail(AUTH, email);
    } catch (error) {
      throw new Error(error);
    }
  };

  const logout = () => signOut(AUTH);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: state?.user?.uid,
          email: state?.user?.email,
          photoURL: state?.user?.photoURL,
          displayName: state?.user?.displayName || profile?.displayName,
          role: state?.user?.role || profile?.role,
        },
        login,
        register,
        logout,
        loginWithGoogle,
        sendPasswordReset,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

const CollectionContext = createContext({
  // ...initialCollectionState,
  sendFileToStorage: () => Promise.resolve(),
  create: () => Promise.resolve(),
  update: () => Promise.resolve(),
  deleteById: () => Promise.resolve(),
  getAll: () => Promise.resolve(),
  getById: () => Promise.resolve(),
  deleteItems: () => Promise.resolve(),
  getFromStorage: () => Promise.resolve(),
  getStorageMetadata: () => Promise.resolve(),
});

CollectionProvider.propTypes = {
  children: PropTypes.node,
};

function CollectionProvider({ children }) {
  const sendFileToStorage = async (file, filePath) => {
    const suffix = file.name.split('.').pop();

    const path = `${filePath}.${suffix}`;

    const storageRef = ref(STORAGE, path);

    const result = await uploadBytes(storageRef, file);

    return result.metadata.fullPath;
  };

  const getFromStorage = async (filePath) => {
    const storageRef = ref(STORAGE, filePath);

    const url = await getDownloadURL(storageRef);

    return url;
  };

  const getStorageMetadata = async (filePath) => {
    const storageRef = ref(STORAGE, filePath);

    const metadata = await getMetadata(storageRef);

    return metadata;
  };

  const create = async (collectionName, data) => {
    const collectionRef = doc(collection(DB, collectionName));

    await setDoc(collectionRef, { ...data, createdAt: new Date().getTime() });
  };

  const update = async (collectionName, id, data, merge = true) => {
    const collectionDataRef = doc(collection(DB, collectionName), id);

    await setDoc(collectionDataRef, { ...data, updatedAt: new Date().getTime() }, { merge });
  };

  const deleteItems = async (collectionName, ids) => {
    const batch = writeBatch(DB);

    ids.forEach((id) => {
      const collectionDataRef = doc(collection(DB, collectionName), id);
      batch.update(collectionDataRef, { isDeleted: true });
    });

    await batch.commit();
  };

  const deleteById = async (collectionName, id, merge = true) => {
    const collectionDataRef = doc(collection(DB, collectionName), id);

    await setDoc(collectionDataRef, { isDeleted: true }, { merge });
  };

  const getAll = async (collectionName) => {
    const collectionRef = collection(DB, collectionName);

    const querySnapshot = await getDocs(collectionRef);

    const data = querySnapshot.docs
      .filter((doc) => !doc.data().isDeleted)
      .map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

    return data;
  };

  const getById = async (collectionName, docId) => {
    try {
      const collectionRef = doc(collection(DB, collectionName), docId);

      const docSnap = await getDoc(collectionRef);

      if (docSnap.exists()) {
        const data = docSnap.data();

        return { ...data, id: docSnap.id };
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  return (
    <CollectionContext.Provider
      value={{
        sendFileToStorage,
        create,
        update,
        deleteById,
        getAll,
        getById,
        getFromStorage,
        getStorageMetadata,
        deleteItems,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
}

export { AuthContext, AuthProvider, CollectionContext, CollectionProvider };
