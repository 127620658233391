import createAvatar from '../utils/createAvatar';
import Avatar from './Avatar';
import PropTypes from 'prop-types';

MyAvatar.propTypes = {
  user: PropTypes.object.isRequired,
};

export default function MyAvatar({ user, ...other }) {
  return (
    <Avatar
      src={user?.photoURL}
      alt={user?.displayName}
      color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {createAvatar(user?.displayName).name}
    </Avatar>
  );
}
