import { PATHS } from 'routes/paths';
import Iconify from 'components/Iconify';

const navConfig = [
  {
    subheader: 'management',
    items: [
      {
        title: 'users',
        path: PATHS.user.list,
        icon: <Iconify icon={'bx:bx-user'} />,
      },
      {
        title: 'news',
        path: PATHS.news.list,
        icon: <Iconify icon={'material-symbols:newspaper'} />,
      },
      {
        title: 'category',
        path: PATHS.category.list,
        icon: <Iconify icon={'bx:bx-category'} />,
      },
      {
        title: 'forms',
        path: PATHS.form.list,
        icon: <Iconify icon={'mdi:form'} />,
      },
      {
        title: 'certificates',
        path: PATHS.certificate.list,
        icon: <Iconify icon={'mdi:certificate'} />,
      },
      {
        title: 'compliance link',
        path: PATHS.compliance.list,
        icon: <Iconify icon={'bx:bx-link'} />,
      },
      {
        title: 'languages',
        path: PATHS.language.list,
        icon: <Iconify icon={'material-symbols:language'} />,
      },
      {
        title: 'popup',
        path: PATHS.popup.list,
        icon: <Iconify icon={'carbon:popup'} />,
      },
    ],
  },
];

export default navConfig;
