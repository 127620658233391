function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  requestSuccess: path(ROOTS_AUTH, '/request-success'),
};

export const PATHS = {
  root: ROOTS_DASHBOARD,
  user: {
    list: path(ROOTS_DASHBOARD, '/user/list'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (userId) => path(ROOTS_DASHBOARD, `/user/edit/${userId}`),
    new: path(ROOTS_DASHBOARD, '/user/new'),
  },
  news: {
    list: path(ROOTS_DASHBOARD, '/news/list'),
    edit: (newsId) => path(ROOTS_DASHBOARD, `/news/edit/${newsId}`),
    new: path(ROOTS_DASHBOARD, '/news/new'),
  },
  category: {
    list: path(ROOTS_DASHBOARD, '/category/list'),
    edit: (categoryId) => path(ROOTS_DASHBOARD, `/category/edit/${categoryId}`),
    new: path(ROOTS_DASHBOARD, '/category/new '),
  },
  form: {
    list: path(ROOTS_DASHBOARD, '/form/list'),
    detail: (formId) => path(ROOTS_DASHBOARD, `/form/${formId}`),
  },
  certificate: {
    list: path(ROOTS_DASHBOARD, '/certificate/list'),
    edit: (certificateId) => path(ROOTS_DASHBOARD, `/certificate/edit/${certificateId}`),
    new: path(ROOTS_DASHBOARD, '/certificate/new'),
  },
  compliance: {
    list: path(ROOTS_DASHBOARD, '/compliance/list'),
    edit: (complianceId) => path(ROOTS_DASHBOARD, `/compliance/edit/${complianceId}`),
    new: path(ROOTS_DASHBOARD, '/compliance/new'),
  },
  language: {
    list: path(ROOTS_DASHBOARD, '/language/list'),
    edit: (languageId) => path(ROOTS_DASHBOARD, `/language/edit/${languageId}`),
    new: path(ROOTS_DASHBOARD, '/language/new'),
  },
  popup: {
    list: path(ROOTS_DASHBOARD, '/popup/list'),
    edit: (popupId) => path(ROOTS_DASHBOARD, `/popup/edit/${popupId}`),
    new: path(ROOTS_DASHBOARD, '/popup/new'),
  },
};

export const PATH_PAGE = {
  page403: '/403',
  page404: '/404',
  page500: '/500',
};
