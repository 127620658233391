import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';
import Login from '../pages/auth/Login';

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized, error } = useAuth();

  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [requestedLocation, setRequestedLocation] = useState(null);

  useEffect(() => {
    if (error) {
      console.error(error);
      enqueueSnackbar(error, { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated || error) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
