export { default as NavSectionVertical } from './vertical';
export { default as NavSectionHorizontal } from './horizontal';

export function isExternalLink(path) {
  return path?.includes('http');
}

function matchPath(path, pathname) {
  const basePath = path.split('/')[1];
  const basePathName = pathname.split('/')[1];

  return basePath === basePathName;
}

export function getActive(path, pathname) {
  return path ? !!matchPath(path, pathname) : false;
}
