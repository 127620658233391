import { Box, Link } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const Logo = forwardRef(({ sx }, ref) => {
  return (
    <Link href='/' underline='none'>
      <Box component='img' ref={ref} src='/logo/logo-bg-dark.svg' sx={{ width: 150 }} {...sx} />
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
};

export default Logo;
