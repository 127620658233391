import { Suspense, lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import LoadingScreen from 'components/LoadingScreen';
import AuthGuard from 'guards/AuthGuard';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import DashboardLayout from 'layouts/dashboard';
import GuestGuard from 'guards/GuestGuard';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to='/auth/login' replace />, index: true },
        {
          path: 'auth',
          children: [
            {
              path: 'login',
              element: (
                <GuestGuard>
                  <Login />
                </GuestGuard>
              ),
            },
            {
              path: 'register',
              element: (
                <GuestGuard>
                  <Register />
                </GuestGuard>
              ),
            },
            { path: 'reset-password', element: <ResetPassword /> },
            { path: 'request-success', element: <RequestSuccess /> },
          ],
        },

        {
          path: 'dashboard',
          element: (
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          ),
          children: [
            { element: <Overview />, index: true },
            {
              path: 'user',
              children: [
                { element: <Navigate to='/user/list' replace />, index: true },
                { path: 'list', element: <UserList /> },
              ],
            },
            {
              path: 'news',
              children: [
                { element: <Navigate to='/news/list' replace />, index: true },
                { path: 'list', element: <NewsList /> },
                { path: 'new', element: <NewsEditCreate /> },
                { path: 'edit/:newsId', element: <NewsEditCreate /> },
              ],
            },
            {
              path: 'category',
              children: [
                { element: <Navigate to='/category/list' replace />, index: true },
                { path: 'list', element: <CategoryList /> },
                { path: 'new', element: <CategoryEditCreate /> },
                { path: 'edit/:categoryId', element: <CategoryEditCreate /> },
              ],
            },
            {
              path: 'form',
              children: [
                { element: <Navigate to='/form/list' replace />, index: true },
                { path: 'list', element: <FormList /> },
                { path: ':formId', element: <FormDetail /> },
              ],
            },
            {
              path: 'certificate',
              children: [
                { element: <Navigate to='/certificate/list' replace />, index: true },
                { path: 'list', element: <CertificateList /> },
                { path: 'new', element: <CertificateEditCreate /> },
                { path: 'edit/:certificateId', element: <CertificateEditCreate /> },
              ],
            },
            {
              path: 'compliance',
              children: [
                { element: <Navigate to='/compliance/list' replace />, index: true },
                { path: 'list', element: <ComplianceList /> },
                { path: 'new', element: <ComplianceEditCreate /> },
                { path: 'edit/:complianceId', element: <ComplianceEditCreate /> },
              ],
            },
            {
              path: 'language',
              children: [
                { element: <Navigate to='/language/list' replace />, index: true },
                { path: 'list', element: <LanguageList /> },
                { path: 'new', element: <LanguageEditCreate /> },
                { path: 'edit/:languageId', element: <LanguageEditCreate /> },
              ],
            },
            {
              path: 'popup',
              children: [
                { element: <Navigate to='/popup/list' replace />, index: true },
                { path: 'list', element: <PopupList /> },
                { path: 'new', element: <PopupEditCreate /> },
                { path: 'edit/:popupId', element: <PopupEditCreate /> },
              ],
            },
            { path: 'permission-denied', element: <PermissionDenied /> },
          ],
        },

        // Main Routes
        {
          path: '*',
          element: <LogoOnlyLayout />,
          children: [
            { path: '500', element: <Page500 /> },
            { path: '404', element: <Page404 /> },
            { path: '403', element: <Page403 /> },
            { path: '*', element: <Navigate to='/404' replace /> },
          ],
        },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('pages/auth/Login')));
const Register = Loadable(lazy(() => import('pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('pages/auth/ResetPassword')));
const RequestSuccess = Loadable(lazy(() => import('pages/auth/RequestSuccess')));

// DASHBOARD
const Overview = Loadable(lazy(() => import('pages/dashboard/Overview')));

const UserList = Loadable(lazy(() => import('pages/UserList')));

const NewsList = Loadable(lazy(() => import('pages/NewsList')));
const NewsEditCreate = Loadable(lazy(() => import('pages/NewsEditCreate')));

const CategoryList = Loadable(lazy(() => import('pages/CategoryList')));
const CategoryEditCreate = Loadable(lazy(() => import('pages/CategoryEditCreate')));

const FormList = Loadable(lazy(() => import('pages/FormList')));
const FormDetail = Loadable(lazy(() => import('pages/FormDetail')));

const CertificateList = Loadable(lazy(() => import('pages/CertificateList')));
const CertificateEditCreate = Loadable(lazy(() => import('pages/CertificateEditCreate')));

const ComplianceList = Loadable(lazy(() => import('pages/ComplianceList')));
const ComplianceEditCreate = Loadable(lazy(() => import('pages/ComplianceEditCreate')));

const LanguageList = Loadable(lazy(() => import('pages/LanguageList')));
const LanguageEditCreate = Loadable(lazy(() => import('pages/LanguageEditCreate')));

const PopupList = Loadable(lazy(() => import('pages/PopupList')));
const PopupEditCreate = Loadable(lazy(() => import('pages/PopupEditCreate')));

const PermissionDenied = Loadable(lazy(() => import('pages/dashboard/PermissionDenied')));
const Page500 = Loadable(lazy(() => import('pages/Page500')));
const Page403 = Loadable(lazy(() => import('pages/Page403')));
const Page404 = Loadable(lazy(() => import('pages/Page404')));
